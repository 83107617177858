import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>OOPS</h1>
    <p>You're looking for a page that doesn&#39;t exist. Click <a href="http://www.joshdeutsch.net/">here</a> to head home.</p>
  </Layout>
)

export default NotFoundPage
